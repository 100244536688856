// import { Footer } from '../_includes/layouts/inc/footer/script';
// import HeaderInit from '../_includes/layouts/inc/header/script';
import DriftChatbot from './genericModules/driftChatBot';
import './utils/cookies';
import { Header } from '../_includes/layouts/inc/header/script/v2';
import { Footer } from '../_includes/layouts/inc/footer/script';

globalThis.loadHeaderFooter = (headerElement, footerElement, classes) => {
  const loadScript = url => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => {
          if (!response.ok) {
            reject(new Error(`Failed to load script from ${url}.`));
          }
          return response.text();
        })
        .then(text => {
          const script = document.createElement('script');
          script.text = text;
          document.head.appendChild(script);
          resolve();
        })
        .catch(error => reject(error));
    });
  };

  function loadStylesheet(url) {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      link.onload = () => {
        resolve();
      };
      link.onerror = () => {
        reject(`Failed to load ${url}`);
      };
      document.head.appendChild(link);
    });
  }

  const loadStyles = urls => {
    urls.forEach(url => loadStylesheet(url));
  };

  const loadScripts = urls => {
    const promises = urls.map(url => loadScript(url));
    return Promise.all(promises);
  };

  const getElement = object => {
    if (typeof object == 'string') {
      return document.querySelector(object);
    } else {
      return object;
    }
  };

  const loadMarketoFormsJs = async () => {
    if (window.MktoForms2) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        resolve(); // Resolving, as we don't want the whole header to fail if Marketo is down.
      };
      script.src = 'https://pages.skydio.com/js/forms2/js/forms2.min.js';
      document.head.appendChild(script);
    });
  };

  fetch(`${FULL_URL}/header-footer.json`).then(result => {
    result.json().then(j => {
      const scriptsToLoad = [];
      if (headerElement) scriptsToLoad.push(`${FULL_URL}/js/header-template.js`);
      if (footerElement) scriptsToLoad.push(`${FULL_URL}/js/footer-template.js`);

      loadStylesheet(`${FULL_URL}/_styles/header-styles.css`)
        .then(() => loadScripts(scriptsToLoad))
        .then(() => loadMarketoFormsJs())
        .then(() => {
          if (headerElement) {
            const headerContainer = getElement(headerElement);
            const supportSite =
              window.location.href.includes('support.skydio.com') ||
              new URLSearchParams(window.location.search).get('emulate') === 'support.skydio.com';
            if (classes) headerContainer.classList.add(classes);
            headerContainer.classList.add('standalone-header-footer');
            headerContainer.innerHTML = window.skydioHeader(
              {
                ...j,
                header: { ...j.header, standalone: true, supportSite },
                filters: { popupJson: () => '' },
              },
              { filters: { popupJson: () => '' } },
            );
            const { header } = new Header(headerContainer.querySelector('[data-header]'));
          }

          if (footerElement) {
            const footerContainer = getElement(footerElement);
            if (classes) footerContainer.classList.add(classes);
            footerContainer.classList.add('standalone-header-footer');
            footerContainer.innerHTML = window.skydioFooter(
              {
                ...j,
                footer: { ...j.footer, standalone: true },
                filters: { popupJson: () => '' },
              },
              { filters: { popupJson: () => '' } },
            );
            Footer(footerContainer.querySelector('[data-footer]'));
          }
        })
        .then(() => {
          document.querySelectorAll('[data-drift]').forEach(chatbotElement => {
            DriftChatbot(chatbotElement);
          });
        });
    });
  });
};
